<template>
  <div
    :style="{ backgroundImage: backgroundUrl }"
    class="signup-page md-scrollbar md-layout md-alignment-center-center"
    :class="{
      'signup-page--jepa': isJEPA,
      'signup-page--jep': isJEP,
      'app-jepa__background': isJEPA,
    }"
  >
    <img
      v-if="isGC"
      class="signup-page__corner-img"
      src="../assets/images/logo_corner.png"
    />

    <img
      v-if="isLCM"
      class="signup-page__corner-img logo-corner-lcm"
      src="../assets/images/logo-corner-lcm.svg"
    />

    <!-- Le logo et le titre -->
    <md-card
      v-if="!isLoading"
      class="meep-form signup-page__form md-layout-item md-xsmall-size-90 md-small-size-80 md-xlarge-size-50"
      md-with-hover
    >
      <md-card-media class="signup-page__form-media">
        <md-icon v-if="logo" class="signup-page__logo" v-html="logo" />
        <MeepIconLogoMeep v-else class="signup-page__logo" />
      </md-card-media>

      <md-card-header>
        <div class="md-title title">{{ $t("signup.title") }}</div>
        <div class="md-subhead">{{ $t("signup.sub-title") }}</div>
      </md-card-header>

      <md-card-content class="signup-page__form-content">
        <!-- Le champ email -->
        <!-- Statut -->
        <div class="meep-input">
          <md-field>
            <label for="scope">Type d'utilisateur</label>
            <md-select id="scope" v-model="user.scope" name="scope">
              <!-- Collaborateur -->
              <md-option v-if="!isJEPA" value="2">
                {{ $$filters.formatScope(2) }}
              </md-option>

              <!-- Client -->
              <md-option value="4">
                {{ $$filters.formatScope(4) }}
              </md-option>
            </md-select>
          </md-field>
        </div>

        <!-- Nom de l'entreprise -->

        <!-- Prénom -->
        <div class="meep-input">
          <md-field :class="{ 'md-invalid': errors.has('firstname') }">
            <label>Prénom *</label>

            <md-input
              v-model="user.firstname"
              v-validate="'required|alpha_spaces'"
              name="firstname"
              type="text"
            />
            <span v-show="errors.has('firstname')" class="md-error">{{
              errors.first("firstname")
            }}</span>
          </md-field>
        </div>

        <!-- Nom -->
        <div class="meep-input">
          <md-field :class="{ 'md-invalid': errors.has('lastname') }">
            <label>Nom *</label>

            <md-input
              v-model="user.lastname"
              v-validate="'required|alpha_spaces'"
              name="lastname"
              type="text"
            />

            <span v-show="errors.has('lastname')" class="md-error">{{
              errors.first("lastname")
            }}</span>
          </md-field>
        </div>

        <!-- Email -->
        <div class="meep-input">
          <md-field :class="{ 'md-invalid': errors.has('email') }">
            <label>E-mail *</label>
            <md-input
              v-model="user.email"
              v-validate="'required|email'"
              name="email"
              type="email"
            />
            <span v-show="errors.has('email')" class="md-error">
              {{ errors.first("email") }}
            </span>
          </md-field>
        </div>

        <!-- Téléphone fixe -->
        <div class="meep-input">
          <md-field :class="{ 'md-invalid': errors.has('telfixe') }">
            <label>Téléphone fixe</label>
            <md-input
              v-model="user.telfixe"
              v-validate="'telephone'"
              name="telfixe"
            />
            <span v-show="errors.has('telfixe')" class="md-error">
              {{ errors.first("telfixe") }}
            </span>
          </md-field>
        </div>

        <!-- Téléphone portable  -->
        <div class="meep-input">
          <md-field :class="{ 'md-invalid': errors.has('telportable') }">
            <label>Téléphone portable</label>
            <md-input
              v-model="user.telportable"
              v-validate="'telephone'"
              name="telportable"
            />
            <span v-show="errors.has('telportable')" class="md-error">{{
              errors.first("telportable")
            }}</span>
          </md-field>
        </div>

        <!-- Numéro et voie -->
        <div class="meep-input">
          <md-field>
            <label>Adresse</label>
            <md-input v-model="user.adresse" name="adresse" type="text" />
          </md-field>
        </div>

        <!-- Ville -->
        <div class="meep-input">
          <md-field :class="{ 'md-invalid': errors.has('ville') }">
            <label>Ville</label>
            <md-input v-model="user.ville" name="ville" type="ville" />
            <span v-show="errors.has('ville')" class="md-error">
              {{ errors.first("ville") }}
            </span>
          </md-field>
        </div>

        <!-- Code postal -->
        <div class="meep-input">
          <md-field
            :class="{ 'md-invalid': errors.has('codepostal') }"
          >
            <label>Code postal</label>
            <md-input
              v-model="user.codepostal"
              v-validate="'numeric|min:5|max:5'"
              name="codepostal"
              type="text"
            />
            <span v-show="errors.has('codepostal')" class="md-error">{{
              errors.first("codepostal")
            }}</span>
          </md-field>
        </div>
      </md-card-content>

      <md-card-actions>
        <md-button
          class="md-dense"
          :class="{
            'md-primary--inverted': !isJEPA,
          }"
          to="/signin"
          >{{ $t("signup.signup-cancel-button") }}
        </md-button>

        <!-- Le bouton de connexion -->
        <md-button
          class="md-raised md-primary signin-button"
          @click="createUser"
          >{{ $t("signup.signup-button") }}
        </md-button>
      </md-card-actions>
    </md-card>

    <LoadingCard v-else class="md-layout-item md-size-50" text="Chargement…" />
  </div>
</template>

<script>
import loginModel from "../model/login.js";
import MeepIconLogoMeep from "../components/icons/MeepIconLogoMeep";
import LoadingCard from "@/components/LoadingCard";

import { mapGetters } from "vuex";

export default {
  name: "SignUp",

  components: {
    MeepIconLogoMeep,
    LoadingCard,
  },

  data() {
    return {
      user: {
        scope: 4,
        firstname: "",
        lastname: "",
        email: "",
        telfixe: "",
        telportable: "",
        adresse: "",
        ville: "",
        company: "",
        codepostal: null,
        origin: "Web",
      },
      companies: [],
      company: null,
      isLoading: false,
    };
  },

  computed: {
    ...mapGetters([
      "organizationTheme",
      "logo",
      "organizationId",
      "backgroundUrl",
      "isGC",
      "isLCM",
      "isJEPA",
      "isJEP",
    ]),
  },

  methods: {
    async createUser() {
      const result = await this.$validator.validateAll();

      if (result) {
        try {
          this.isLoading = true;
          if (this.isJEPA) {
            this.user.company = `${this.user.lastname} ${this.user.firstname}`;
          }

          this.user.organization_id = this.organizationId;

          await loginModel.signup(this.user);
          this.$router.push("/signin");

          this.$toasted.global.AppSucces({
            message: `Nous avons reçu votre demande d'inscription.Votre compte est en attente de validation par l'administrateur. Merci de bien vouloir patienter.`,
          });
        } catch (err) {
          this.$toasted.global.AppError({
            message: err.msg,
          });
        } finally {
          this.isLoading = false;
        }
      } else {
        this.$toasted.global.AppInfo({
          message: "Vous devez remplir les champs manquants",
        });
      }
    },
  },
};
</script>

<style lang="scss">
@import "../styles/_variable.scss";

.signup-page {
  height: 100vh;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: auto;

  .signup-page__logo {
    max-width: 275px;
    width: 100%;
    height: auto;

    /deep/ svg {
      width: 100%;
      height: auto;
    }
  }

  &.signup-page--jepa,
  &.signup-page--jep {
    .signup-page__form-media {
      height: auto;

      .signup-page__logo {
        margin-top: 23px;
        margin-bottom: 23px;
        width: 235px;
        height: auto;
      }
    }
  }

  &__corner-img {
    position: absolute;
    transform: rotate(90deg);
    top: 0;
    left: 0;
    width: 20%;

    &.logo-corner-lcm {
      top: 32px;
      width: 20em;
      transform: rotate(0) translate(-1em);
    }
  }

  &__form.md-card.meep-form {
    margin: 100px 0;
    cursor: default;
    padding: 20px 50px;
    border-radius: 27px;
    box-shadow: none;
    max-width: 824px;

    &.md-with-hover:hover {
      box-shadow: none;
      cursor: default;
    }

    .md-card {
      &-header {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 25px;

        .md-title {
          font-size: toRem(18);
          font-family: var(--font-bold);
          @include for-lg{
            font-size: toRem(22)
          }
        }

        .md-subhead {
          opacity: 1;
          font-size: 17px;
          font-family: var(--font-x);
        }
      }

      &-actions,
      &-content {
        padding: 0;
        flex-wrap: wrap;
      }
      &-actions {
        margin: 40px 0 50px;
        .md-button {
          margin-left: 10px;
          min-width: unset;
          width: fit-content;
        }
      }
    }

    .meep-input {
      flex: 0 0 100%;
    }
  }

  &__form-media {
    width: 100%;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .signup-button {
    color: var(--text-primary) !important;
  }
  .signin-button{
    min-width: unset;
    min-height: unset;
    height: 36px;
    @include for-lg{
      height: 43px;
    }
  }
  .loading-card {
    border-radius: 27px;
    height: 500px;
    justify-content: center;
    align-items: center;
    display: flex;
    box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.2);

    .md-progress-spinner-draw {
      width: 160px !important;
      height: 160px !important;
    }

    &__message {
      margin-top: 30px;
      font-size: 22px;
      font-family: var(--font-extrabold);
    }
  }
}
</style>
